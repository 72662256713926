<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      @click:outside="closeViewItem"
      @keydown.esc="closeViewItem"
      :max-width="ancho"
    >
      <v-card>
        <v-card-text>
          <div v-if="!esImagen()">
            <iframe
              class="container-frame"
              v-if="srcViewItem.length > 0"
              :src="srcViewItem"
              :style="estilosIframe"
            ></iframe>
          </div>
          <div v-else>
            <img v-if="item.previewURL.length > 0" :src="item.previewURL" :style="estilosIframe" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'PreviewDocument',
  props: {
    item: {
      type: Object,
      default: null
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    item() {
      this.viewItem();
      this.calcularEstilosIframe();
    }
  },
  data() {
    return {
      srcViewItem: '',
      estilosIframe: '',
      ancho: '',
      alto: ''
    };
  },
  methods: {
    esImagen() {
      if (this.item?.extension === 'jpg' || this.item?.extension === 'jpeg' || this.item?.extension === 'png')
        return true;
      else return false;
    },
    async calcularEstilosIframe() {
      if (this.item?.extension === 'jpg' || this.item?.extension === 'jpeg' || this.item?.extension === 'png') {
        let foto = new Image();
        const resFoto = await this.loadImg(foto, this.item?.previewURL);
        this.ancho = resFoto.naturalWidth + 50;
        this.alto = resFoto.naturalHeight;
        this.estilosIframe = `width: ${this.ancho - 50}px; height: ${this.alto}px;`;
      } else {
        this.estilosIframe = `width: 100%; height: calc(100vh - 212px);`;
        this.ancho = 2000;
      }
    },
    loadImg(img, url) {
      return new Promise((resolve, reject) => {
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = () => reject(img);
      });
    },
    closeViewItem() {
      this.$emit('close');
      this.srcViewItem = '';
    },
    viewItem() {
      let urlbase = '';

      if (
        this.item?.extension === 'doc' ||
        this.item?.extension === 'docx' ||
        this.item?.extension === 'ppt' ||
        this.item?.extension === 'pptx' ||
        this.item?.extension === 'xls' ||
        this.item?.extension === 'xlsx' ||
        this.item?.extension === 'pdf' ||
        this.item?.extension === 'jpg' ||
        this.item?.extension === 'jpeg' ||
        this.item?.extension === 'png'
      ) {
        urlbase = 'https://docs.google.com/a/umd.edu/viewer?url=';
        this.srcViewItem = `${urlbase}${this.item?.previewURL}&embedded=true`;
      }
      //this.item?.previewURL = 'https://40.88.149.7:4432/kanban/EXPORT-OPEX-CAMPO-20220115080169_1642295058_2022-01-16_010418.xlsx';
    }
  }
};
</script>
<style lang="scss" scoped>
.container-frame {
  margin: 25px 0px;
}
.v-card {
  height: 100%;
}
</style>
